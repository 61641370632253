@use "sass:math";

.map {
	$self: &;

	border: 1rem solid rgba(var(--theme-color-background-2),1);

	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: math.div(9, 16) * 100%;

		.grid--fs & {
			padding-top: math.div(5, 16) * 100%;
		}
	}

	&__view {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		img {
			max-width: none;
		}
	}

	&__locations {
		display: none;
	}

}
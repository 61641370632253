

$font-sans: 'CallunaSans', sans-serif;
$font-serif: 'Calluna', serif;

@mixin font-sans-reg() {
	font-family: $font-sans;
	font-weight: $font-weight-regular;
}
%font-sans-reg {
	@include font-sans-reg;
}

@mixin font-sans-bold() {
	font-family: $font-sans;
	font-weight: $font-weight-extra-bold;
}
%font-sans-bold {
	@include font-sans-bold;
}

@mixin font-sans-black() {
	font-family: $font-sans;
	font-weight: $font-weight-black;
}
%font-sans-black {
	@include font-sans-black;
}


@mixin font-serif-reg() {
	font-family: $font-serif;
	font-weight: $font-weight-regular;
}
%font-serif-reg {
	@include font-serif-reg;
}

@mixin font-serif-bold() {
	font-family: $font-serif;
	font-weight: $font-weight-bold;
}
%font-serif-bold {
	@include font-serif-bold;
}

@mixin font-serif-black() {
	font-family: $font-serif;
	font-weight: $font-weight-black;
}
%font-serif-black {
	@include font-serif-black;
}

/**
 * FONT SIZE MIXINS
 **/


@mixin font-size-body() {
	@include font-size(1.8, 2.4, 0);

	@include respond-to(display) {
		@include font-size(1.9, 2.7, 0);
	}
}
@mixin font-size-body-large() {
	@include respond-to(display) {
		@include font-size(2.3, 3.1, 0);
	}
}
@mixin font-size-body-medium-large() {
	@include respond-to(display) {
		@include font-size(1.8, 2.6, 0);
	}
}
@mixin font-size-body-medium() {
	@include respond-to(display) {
		@include font-size(1.6, 2.3, 0);
	}
}
@mixin font-size-body-small() {
	@include font-size(1.3, 2, 0);
	@include respond-to(display_medium) {
		@include font-size(1.4, 2.2, 0);
	}
}

@mixin font-size-navigation-primary-first() {
	@include respond-to(display) {
		@include font-size(3, 2.4, 25);
	}
	@include respond-to(display_medium) {
		@include font-size(3.6, 3, 25);
	}
}

@mixin font-size-navigation-primary-second() {
	@include respond-to(display) {
		@include font-size(2, 3.3, 25);
	}
	@include respond-to(display_medium) {
		@include font-size(2.2, 3.5, 25);
	}
}
@mixin font-size-navigation-footer() {
	@include font-size(1.4, 1.8, 15);
}


@mixin font-size-h1() {
	@include font-size(3.2, 3.8, 25);
	@include respond-to(display) {
		@include font-size(5.2, 5.8, 25);
	}
	//include font-size(6, 6, -20);
}
@mixin font-size-h2() {
	@include font-size(2.8, 3.3, 50);
	@include respond-to(display) {
		@include font-size(3.9, 4.4, 50);
	}
}
@mixin font-size-h3() {
	@include font-size(2.1, 2.9, 50);
	@include respond-to(display) {
		@include font-size(3.3, 4, 50);
	}
}
@mixin font-size-h4() {
	@include font-size-h3();
}
@mixin font-size-h5() {
	@include font-size-body();
}
@mixin font-size-h6() {
	@include font-size-body();
}

@mixin font-size-button() {
	@include font-size(1.5, null, 50);
}
@mixin font-size-button-large() {
	@include font-size(2.0, null, 50);
}

@mixin font-size-button-serif() {
	@include font-size(1.9, 2.3);
}
@mixin font-size-button-serif-large() {
	@include font-size(2.0, 2.4);
}

@mixin font-size-teaser-title() {
	@include respond-to(display) {
		@include font-size(2.1, 2.8, 0);
	}
}

@mixin font-size-timeline-subtitle() {
	@include font-size(2.1, 2.8, 0);
}



/**
 * FONT STYLE MIXINS
 */



@mixin font-style-body() {
	@extend %font-serif-reg;
	@include font-size-body;
}
@mixin font-style-body-sans() {
	@extend %font-sans-reg;
	@include font-size-body;
}

@mixin font-style-body-small() {
	@extend %font-serif-reg;
	@include font-size-body-small;
}
@mixin font-style-body-small-sans() {
	@extend %font-sans-reg;
	@include font-size-body-small;
}

@mixin font-style-body-medium() {
	@extend %font-serif-reg;
	@include font-size-body-medium;
}

@mixin font-style-body-medium-large() {
	@extend %font-serif-reg;
	@include font-size-body-medium-large;
}

@mixin font-style-navigation-primary-first() {
	@extend %font-serif-reg;
	@include font-size-navigation-primary-first;
}

@mixin font-style-navigation-primary-second() {
	@extend %font-serif-bold;
	font-style: italic;
	@include font-size-navigation-primary-second;
}

@mixin font-style-navigation-footer() {
	@extend %font-sans-reg;
	@include font-size-navigation-footer;
}
@mixin font-style-navigation-prevnext() {
	@extend %font-serif-reg;
	@include font-size-body;
}


@mixin font-style-headline-1() {
	@extend %font-sans-reg;
	@include font-size-h1;
}
@mixin font-style-headline-2() {
	@extend %font-sans-reg;
	@include font-size-h2;
}
@mixin font-style-headline-3() {
	@extend %font-sans-bold;
	@include font-size-h3;
}
@mixin font-style-headline-4() {
	@extend %font-serif-bold;
	@include font-size-h4;
}
@mixin font-style-headline-5() {
	@extend %font-serif-bold;
	@include font-size-h5;
}
@mixin font-style-headline-6() {
	@extend %font-sans-bold;
	@include font-size-h6;
}

@mixin font-style-headline-1-serif() {
	@extend %font-serif-reg;
	@include font-size-h1;
}
@mixin font-style-headline-2-serif() {
	@extend %font-serif-reg;
	@include font-size-h2;
}
@mixin font-style-headline-3-serif() {
	@extend %font-serif-reg;
	@include font-size-h3;
}
@mixin font-style-headline-4-serif() {
	@extend %font-serif-reg;
	@include font-size-h4;
}
@mixin font-style-headline-5-serif() {
	@extend %font-serif-reg;
	@include font-size-h5;
}
@mixin font-style-headline-6-serif() {
	@extend %font-serif-reg;
	@include font-size-h6;
}

@mixin font-style-headline-1-serif-thin() {
	@extend %font-serif-bold;
	@include font-size-h1;
}
@mixin font-style-headline-2-serif-thin() {
	@extend %font-serif-bold;
	@include font-size-h2;
}
@mixin font-style-headline-3-serif-thin() {
	@extend %font-serif-bold;
	@include font-size-h3;
}
@mixin font-style-headline-4-serif-thin() {
	@extend %font-serif-bold;
	@include font-size-h4;
}

@mixin font-style-button() {
	@extend %font-sans-black;
	@include font-size-button;
}

@mixin font-style-button-serif() {
	@extend %font-serif-reg;
	@include font-size-button-serif;
}


@mixin font-style-intro-title() {
	@extend %font-serif-bold;
	@include font-size-h2;
}

.buttons {
	$self: &;

	display: flex;

	.button {
		margin-right: 0.3rem;

		&:last-child {
			margin: 0;
		}
	}

	&--vertical {
		flex-direction: column;
		.button {
			margin: 0;
			margin-bottom: 0.4rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--left {
		justify-content: flex-start;
	}

	&--center {
		justify-content: center;
	}

	&--right {
		justify-content: flex-end;
	}

}
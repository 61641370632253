.document-list {
	$self: &;

	&__item {
		--color: var(--theme-color-font-std);

		--opacity: 1;
		//--transform: translate3d(-4rem,0,0);
		--transform: translate3d(0,0,0);
		--background: transparent;
		--blend-mode: normal;

		&:hover {
			--color: var(--theme-color-highlight-dark);

			--button-border-color: var(--color-red);
			--button-color: var(--theme-color-font-std-inverse);
			--button-bg-color: rgba(var(--color-red), 1);

			--opacity: 0.95;
			--transform: translate3d(0,0,0) scale3d(1.05,1.05,1);
			--background: rgba(var(--theme-color-highlight-dark), 1);

		}

		padding-block: 6rem;
		margin-bottom: var(--layout-gutter-grid);

		position: relative;
		&:after {
			content: '';
			z-index: -1;
			background: rgba(var(--theme-color-background-2), 1);
			position: absolute;
			top:0;
			bottom:0;
			right: 0;
			left:0;


			@include respond-to(tablet_landscape) {
				right:var(--layout-gutter-page);
				left: var(--layout-gutter-page);
			}

		}



	}

	&__image,
	&__content {

	}

	&__image {
		transition: background-color 0.35s;
		background: var(--background);
		overflow: hidden;
		border: 1rem solid rgba(var(--color-white), 1);
		//margin-bottom: 1em;

		IMG {
			//width: calc(100% + 5rem);
			width: 100%;
			opacity: var(--opacity);
			transition: opacity 0.35s, transform 0.35s;
			transform: var(--transform);
			mix-blend-mode: var(--blend-mode);

		}
	}
	&__content {

	}

	&__title {
		@include font-style-headline-3-serif;
		font-weight: $font-weight-bold;
		color: rgba(var(--theme-color-highlight-dark), 1);
		margin-bottom: 1rem;
	}

	&__text {
		transition: color 0.35s;
		margin-bottom: 4rem;
		color: rgba(var(--color), 1);

	}

	&__title,
	&__text {
		@include respond-to(tablet_landscape) {
			width: 80%;
		}
	}
}


.document-slider {

	&__column {

		//padding-top: unset !important;
		//padding-bottom: unset !important;
		//padding-inline: var(--layout-gutter-grid);
		//& + & {
		//	padding-block-start: 0 !important;
		//}
		//
		//@include respond-to(tablet) {
		//	padding-block: 2rem !important;
		//
		//
		//	& + & {
		//		padding-block-start: initial !important;
		//	}
		//}
		//
		//@include respond-to(tablet_landscape) {
		//	padding-block: initial !important;
		//	padding-inline: initial !important;
		//}

		padding-inline: var(--layout-gutter-grid);
		@include respond-to(tablet) {
			padding-inline: 0;
			&:first-child {
				padding-inline-start: var(--layout-gutter-grid);
			}

			&:last-child {
				padding-inline-end: var(--layout-gutter-grid);
			}
		}

		@include respond-to(tablet_landscape) {
			padding-inline: 0;
		}


		@include respond-to(until_tablet) {
			& + & {
				padding-block-start: 0 !important;
			}
		}

		@include respond-to(until_tablet_landscape) {
			padding-block: 2rem !important;

		}

		@include respond-to(until_tablet_landscape) {


			//padding-inline: var(--layout-gutter-grid);
		}
	}
}
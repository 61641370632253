.tooltip {
	$arrowSize: 1rem;
	$height: 3.1rem;
	$spacing: 0.8rem;

	--background-color: var(--theme-color-validation-success);

	@include font-style-body-small-sans;

	transition: all $tt-std ease;
	//transform: translateY(#{ 0 - $height - $arrowSize - $spacing - ($height / 1.5)});
	position: absolute;
	bottom:calc(100% + #{$arrowSize} + #{$spacing});
	z-index: 100;
	opacity: 0;
	pointer-events: none;

	width: 100%;
	//height: $height;
	padding: 0 0.4rem;

	color: rgba(var(--color-white), 1);
	background: rgba(var(--background-color), 1);
	//border-radius: $height;
	border-radius: 0.4rem;

	text-align: center;

	&:after {
		content: '';
		position: absolute;
		top: 99%;
		right: 2.2rem;
		//margin-left: -8px;
		width: 0; height: 0;
		border-top: $arrowSize solid rgba(var(--background-color), 1);
		border-right: $arrowSize solid transparent;
		border-left: $arrowSize solid transparent;
	}


	&--half {
		width: 80%;
		left: 20%;
		@include respond-to(tablet_landscape) {
			width: 50%;
			left: 50%;
		}
	}

	@at-root .form__group--has-error &,
	&--visible {
		--background-color: var(--theme-color-validation-error);
		opacity: 1;
		//transform: translateY(#{ 0 - $height - $arrowSize - $spacing});
	}


}
/**
 * breakpoints
 **/

@include add_breakpoint('phone',                        375px); //XXS
@include add_breakpoint('phone_landscape',              480px); //XS
@include add_breakpoint('tablet',                       768px); //S
@include add_breakpoint('tablet_landscape',             1024px); //M
@include add_breakpoint('display',                      1280px); //L
@include add_breakpoint('display_medium',               1440px); //L
@include add_breakpoint('display_large',                1600px); //XL
@include add_breakpoint('display_extralarge',           1920px); //XXL

@include add_breakpoint('until_phone',                  screen max-width 374px);
@include add_breakpoint('until_phone_landscape',        screen max-width 479px);
@include add_breakpoint('until_tablet',                 screen max-width 767px);
@include add_breakpoint('until_tablet_landscape',       screen max-width 1023px);
@include add_breakpoint('until_display',                screen max-width 1280px);
@include add_breakpoint('until_display_medium',         screen max-width 1440px);

@include add_breakpoint('after_tablet_landscape',       1025px);

@include add_breakpoint('min_height_tablet',                    screen min-height 1024px);
@include add_breakpoint('min_height_tablet_landscape',          screen min-height 768px);

@include add_breakpoint('min_height_after_tablet',                    screen min-height 1025px);
@include add_breakpoint('min_height_after_tablet_landscape',          screen min-height 769px);

@include add_breakpoint('tablet_810_portrait',                       (768px) (orientation portrait));
/**
 * layout
 */
$susy: (
	'columns': susy-repeat(12, 1fr),
	'gutters': 0.1,
	'spread': 'narrow',
	'container-spread': 'narrow',
);


$layout-container-width: 0rem;
$layout-default-vertical-layout-breakpoint: tablet_landscape;
$layout-default-mobile-navigation-breakpoint: tablet_landscape;

/**
 * font
 */

/**
 * 100: Ultra Light
 * 200: Thin
 * 300: Light
 * 400: Regular
 * 500: Semi Bold
 * 600: Bold
 * 700: Extra Bold
 * 800: Heavy
 * 900: Ultra Heavy
 */

$font-weight-thin:              200;
$font-weight-light:             300;
$font-weight-regular:           400;
$font-weight-semi-bold:         500;
$font-weight-bold:              600;
$font-weight-extra-bold:        700;
$font-weight-black:             800;

/**
 * views
 */


/**
 * colors
 */

$color-white: #fff;
$color-white-10: rgba($color-white, 0.1);
$color-white-20: rgba($color-white, 0.2);
$color-white-30: rgba($color-white, 0.3);
$color-white-50: rgba($color-white, 0.5);
$color-white-70: rgba($color-white, 0.7);

$color-black: #000;
$color-black-85: rgba($color-black, 0.85);
$color-black-70: rgba($color-black, 0.7);
$color-black-50: rgba($color-black, 0.5);
$color-black-30: rgba($color-black, 0.3);
$color-black-20: rgba($color-black, 0.2);
$color-black-10: rgba($color-black, 0.1);

/**
 * z-order
 **/
$z-main: 10;
$z-header: 1000;




/**
 * transition times
 */
$tt-std: 200ms;

/**
 * stuff
 */

%object-fit-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

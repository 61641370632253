.footer {
	$self: &;

	width: 100%;
	height: var(--layout-height-footer);

	&__main,
	&__meta {

	}

	&__main {
		height: var(--layout-height-footer-main);
		background: rgba(var(--theme-color-background-footer-main), 1);
		color: rgba(var(--theme-color-font-std-inverse), 1);

		padding-block: var(--layout-gutter-grid);
	}

	&__meta {
		@include font-style-body-small-sans;
		height: var(--layout-height-footer-meta);
		background: rgba(var(--theme-color-background-footer-meta), 1);
		color: rgba(var(--theme-color-font-std-inverse), 1);
	}

	&__container {
		@extend %container;
		position: relative;

		#{$self}__meta & {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			row-gap: 0.5rem;
			padding-block: 2rem;
			@include respond-to(tablet) {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				row-gap: 0;
				padding:0;

			}

		}

		#{$self}__main & {
			height: 100%;
			display: grid;

			grid-template-columns: auto;

			@include respond-to(tablet_landscape) {
				grid-template-columns: 60% auto;
			}


			grid-column-gap: var(--layout-gutter-grid);
			align-items: center;
		}
	}

}
.quote {
	$self: &;

	--color: rgba(var(--theme-color-highlight-dark), 1);

	text-align: center;
	color: var(--color);
	font-style: italic;

	margin-block-end: var(--layout-spacing-section);
	@include respond-to(tablet) {
		margin-block-end: 0;
	}

	&__ornament {
		width: 100%;
		display: flex;
		justify-content: center;

		svg {
			fill: var(--color);
			width: 6rem;
		}
	}

	&__content {
		margin: 1.5rem 0;
	}

	&__title {
		&--headline {
			@include font-size-h3;
			@extend %font-serif-black;
			margin-bottom: 0.4em;
		}
		&--subline {
			color: rgba(var(--color-black), 1);
			margin-top: 1.2rem;
		}
	}

	&__text {
		@include font-size-body-large;
	}
}
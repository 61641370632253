.ingredients-list {
	$self: &;

	--highlight-color: rgba(var(--theme-color-highlight-dark), 1);

	position: relative;
	padding-bottom: 2rem;
	margin-bottom: var(--layout-gutter-grid);

	&:after {
		@include font-size-h2();
		color: var(--highlight-color);

		position: absolute;
		bottom:0;
		left:0;
		width: 100%;
		height: 0.5em;
		content: '';

		mask-image: url('/static/images/svg/ornament-line-2.svg');
		mask-repeat: no-repeat;
		mask-size: cover;
		mask-position: center;
		background: currentColor;
	}

	&__group {
		margin-bottom: 2rem;
	}

	&__ingredients {
		color: var(--highlight-color);
		margin: 0;
		padding:0;
	}

	&__ingredient {
		display: flex;
		position: relative;

		padding:0;
		margin:0 0 0.4rem 0;

		&:before {
			display: block;
			flex-shrink: 0;
			content: '';

			width: 3.5rem;
			height: 1.1rem;
			margin-top: 0.8rem;
			margin-right: 1rem;

			mask-image: url('/static/images/svg/ornament-7.svg');
			mask-repeat: no-repeat;
			mask-size: cover;
			mask-position: center;
			background: currentColor;
		}
	}

	&__title {
		color: var(--highlight-color);
		text-align: center;
	}

	&__subtitle {
		@include font-size-body;
		font-style: italic;
		margin: 0 0 1rem 0;
	}



}
.video {
	$self: &;

	position: relative;
	margin-bottom: var(--layout-gutter);

	&--youtube {
		.layout--livemode & {

			&:before {
				content: "";
				display: block;
				padding-top: 56%; /* 16:9 Format */
			}

			IFRAME {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

	&--asset {
		video {
			width: 100%;
			height: auto;
		}
	}

}
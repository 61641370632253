

/** mixins **/
@use "sass:math";

@mixin font-size($font-size, $line-height: FALSE, $letter-spacing: FALSE, $double: FALSE) {

	@if $double != FALSE {
		$font-size: $font-size * 2;
		$line-height: $line-height * 2;
	}

	font-size: calc(#{$font-size + 'rem'} * var(--font-size-multi, 1));

	@if $line-height != FALSE {
		//line-height:  ($line-height / ($font-size / 100)) / 100 ;
		line-height:  math.div(math.div($line-height, math.div($font-size, 100)), 100);
	}
	@else {
		line-height: 1;
	}

	@if $letter-spacing != FALSE {
		//photoshop
		//letter-spacing: $letter-spacing / 1000 + rem; // PhotoshopValue / 1000 = LetterSpacingEM
		//letter-spacing: $letter-spacing / 1000 + em; // PhotoshopValue / 1000 = LetterSpacingEM

		//sketch
		//letter-spacing: $letter-spacing / 10 + rem;

		//pixel
		//letter-spacing: $letter-spacing * ($letter-spacing * 10) / 1000 + px; // PhotpshopValue * PixelFontSize / 1000 = LetterSpacingPX
		//letter-spacing: $letter-spacing * math.div(($letter-spacing * 10), 1000) + px; // PhotpshopValue * PixelFontSize / 1000 = LetterSpacingPX

		//illustrator to pixel
		//letter-spacing: ($letter-spacing) * 0.08 + px;
		//letter-spacing: ($letter-spacing) / 1000 + em;
		letter-spacing: math.div($letter-spacing, 1000) + em;

	}
}

@mixin letter-spacing($letter-spacing) {
	//illustrator to milli ems
	letter-spacing: math.div($letter-spacing, 1000) + em;
}



//@include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: math.div($height, $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin container ($preventMaxWidth: false) {
	width: 100%;
	margin:0 auto;
	max-width: var(--layout-max-width);

	@if $preventMaxWidth == false {
		padding: 0 var(--layout-gutter-page, var(--layout-gutter-grid));
		margin: 0 auto;
		max-width: calc(var(--layout-max-width, initial) + (2 * var(--layout-gutter-page, var(--layout-gutter-grid))));
	}
}

%container {
	@include container();
}
%container-prevent-max-width {
	@include container(true);
}

@mixin grid () {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: var(--gutter-grid, var(--layout-gutter-grid));
	grid-row-gap: var(--gutter-grid, var(--layout-gutter-grid));
}

%grid {
	@include grid();
}



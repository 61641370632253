.timeline {
	$self: &;

	margin-bottom: 8rem;

	&__years {
		//display: none;
		width: 100%;
		padding-bottom: 3rem;

	}
	&__year {
		@include font-style-body-medium-large;
		width: 10rem;
		text-align: center;
		cursor: pointer;

		&:hover,
		&.is-selected {
			color: rgba(var(--theme-color-highlight-dark), 1);
		}
	}

	&__items {}
	&__item {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: auto var(--layout-spacing-grid) auto;
		grid-template-areas:
			'image'
			'.'
			'content'
		;

		@include respond-to(tablet) {
			grid-template-columns: 50% 50%;
			grid-template-rows: auto;
			grid-template-areas: 'image content';
		}
		align-items: center;
		margin-bottom: 2rem;
	}

	&__image {
		grid-area: image;

		@include respond-to(tablet) {
			padding: 0 18%;
		}

		IMG {
			border: 1rem solid rgba(var(--color-white), 1);
		}
	}
	&__content {
		grid-area: content;
	}

	&__title {
		@include font-style-headline-3-serif;
		font-weight: $font-weight-extra-bold;
		margin-bottom: 2rem;
		text-align: center;
		@include respond-to(tablet) {
			text-align: left;
		}
	}
	&__subtitle {
		@include font-size-body-large;
		font-weight: $font-weight-bold;
	}
	&__text {
		P {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__pager {}

	&__controls {
		display: flex;
		align-items: center;
		justify-content: center;
		margin:2rem auto 2rem;
	}

	&__control {

		display: flex;
		align-items: center;

		&:hover {
			color:  rgba(var(--theme-color-highlight-dark), 1);
		}

		&--disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		&--prev,
		&--next {
			svg {
				width: 6.2rem;
				fill: currentColor;
			}
		}
	}

	&__control-line {

		display: block;
		width: 30%;
		margin: 0 3rem;
		&:after {
			display: block;
			content: '';
			width: 100%;
			//background: url('/static/images/svg/ornament-line-1.svg') no-repeat;
			//background-size: contain;
			//background-position: center;
			height: 1em;

			mask-image: url('/static/images/svg/ornament-line-1.svg');
			mask-repeat: no-repeat;
			mask-size: cover;
			mask-position: center;
			background: currentColor;
		}
	}



	//flickity

	.flickity-page-dots {
		position: static;
		padding: 2rem 0;
		text-align: center;

		.dot {
			&.is-selected {
				background: rgba(var(--theme-color-highlight-dark), 1);
			}
		}
	}


	.flickity-enabled  {
		.flickity-page-dots {
			opacity: 1;
			.dot {
			}
		}

		.flickity-viewport {
			min-height: 0;
		}
	}

	.flickity-viewport {
		min-height: var(--mh);
		transition: height 0.2s;
	}

	.flickity-prev-next-button {
		top: unset;
		width: 6.2rem;
		height: 6.2rem;
		transform: translateY(0);

		.flickity-button-icon {
			position: static;
			width: 100%;
			height: 100%;
		}

		&.previous {
			left:50%;
			transform: translateX(-200%);
		}

		&.next {
			right: 50%;
			transform: translateX(200%);
		}
	}

	.flickity-button {
		background: transparent;
		border-radius: 0;
		padding:0;

		&:hover {
			color: rgba(var(--theme-color-highlight-dark), 1);
		}

		svg {
			fill: currentColor;
		}
	}
}
.info-box {
	$self: &;

	--highlight-color: rgba(var(--theme-color-highlight-dark), 1);

	position: relative;
	padding-bottom: 2rem;
	margin-bottom: var(--layout-gutter-grid);


	&__title:after,
	&:after {
		@include font-size-h2();
		color: var(--highlight-color);

		position: absolute;
		bottom:0;
		left:0;
		width: 100%;
		height: 0.5em;
		content: '';

		mask-image: url('/static/images/svg/ornament-line-2.svg');
		mask-repeat: no-repeat;
		mask-size: cover;
		mask-position: center;
		background: currentColor;
	}

	&__group {
		margin-bottom: 2rem;
	}

	&__content {
		color: var(--highlight-color);

	}

	A:not(.button) {
		color: rgba(var(--highlight-color), 1);
		border-bottom: 0.2rem solid transparent;
		&:hover {
			border-color: rgba(var(--color-red), 1);
		}
	}

	&__title {
		color: var(--highlight-color);
		text-align: center;
		position: relative;

		min-height: 1.9rem;

		.headline {
			height: 7.1rem;
		}

		//height: 7.1rem;
	}

	&__subtitle {
		@include font-size-body;
		font-style: italic;
		margin: 0 0 1rem 0;
	}



}
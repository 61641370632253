.numberlist {
	$self: &;

	UL {
		padding:0;
		margin: 0;
		list-style: none;
	}



	&__item {
		display: grid;

		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: auto auto;
		@include respond-to(tablet) {
			grid-template-rows: auto;
		}

		grid-column-gap: var(--layout-gutter-grid);
		margin-bottom: var(--layout-gutter-grid);
		padding-bottom: calc(var(--layout-gutter-grid) + 1.8rem);

		position: relative;

		&:before {
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			height: 1.8rem;
			background: url("/static/images/svg/ornament-line-1.svg") no-repeat center;
			background-size: cover;
			content: '';
		}

		&:last-child {
			&:before {
				display: none;
			}
		}
	}

	&__number {
		@include font-size(7.2);
		grid-column: 1 / 2;
		align-self: center;

		display: none;
		@include respond-to(tablet) {
			display: block;
		}

		#{$self}--hide-numbers & {
			visibility: hidden;
		}
	}

	&__image {
		grid-column: span 12;
		grid-row: 1;
		@include respond-to(tablet) {
			grid-column: 2 / 6;

		}
		align-self: center;
	}

	&__content {
		grid-column: span 12;
		grid-row: 2;
		@include respond-to(tablet) {
			grid-column: 7 / 12;
			grid-row: 1;
		}

		align-self: center;

		A:not(.button) {
			color: rgba(var(--color-red), 1);
			border-bottom: 0.2rem solid transparent;
			&:hover {
				border-color: rgba(var(--color-red), 1);
			}
		}
	}

}